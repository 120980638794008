import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import SEO from '../components/seo';
import './index.scss';
import ProductHeader from '../components/product-header';
import ArrowRightIcon from '!svg-react-loader!../images/vectors/arrow_right.svg';
import SRLogo from '!svg-react-loader!../images/vectors/smart-rep-logo.svg';
import Feedback from './sr-feedback';
import SRBookDemoPage from '../components/book-demo/book-smart-rep';

import { OfferingItemContainer } from '../components/extra-components';
import * as Constants from '../utils/constants';
import DetailedSpecs from '../components/detailed-specs';
import ComingSoon from '../components/coming-soon';
import { srPharmaFeedbackData } from '../utils/constants';
import { CTypes } from '../utils/constants';


const SmartRepLogo = () => {
  return (
    <SRLogo width={25} height={28} alt={'smart rep logo'} />
  );
};


const SRPharmaceuticalsOffering = () => {
  const viewDetailsClicked = (page) => {
    return (e) => {
      e.stopPropagation();
      console.log('view details clicked', page, e);
      return true;
    };
  };
  return (
    <>
      <div className='micro-app-offering-header'>
        <h4>Offering</h4>
        <p className='offering-pricing'>
          Powerful micro apps to boost awareness of your products and scale up your business.<br />
          Price: Pay-per-use
        </p>
      </div>
      <OfferingItemContainer list={Constants.pharmaOfferingContentList} fullWidth />
    </>
  );
};

const SRPharmaDetailsPage = ({ location, navigate }) => {

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log('running timeout');
  //     window.scroll(0, 0);
  //   }, 100);
  // }, []);

  const variants = {
    hidden: (custom) => {
      return {
        opacity: 0,
        y: 0
      };
    },
    visible: (custom) => ({
      opacity: 1,
      y: 0
    })
  };

  return (
    <div className='mt-page-root container'>
      <SEO title="Smart Rep - Offering Details" />
      <div className='page-fixed-header container'>
        <ProductHeader
          title={'Smart Rep'}
          productImage={SmartRepLogo}
          backLinkTo={`/#home-SR`}
          featureText={`for Pharmaceutical Cos.`}
          bookDemoPage={SRBookDemoPage}
          cType={CTypes.SR_PHARMA}
        />
      </div>
      <motion.div
        className="container-fluid main-container-full sa-page-scroller"
        initial={'hidden'}
        animate={'visible'}
        variants={{
          visible: {
            transition: {
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05
            }
          },
          hidden: {
            transition: {
              duration: 0.3
            }
          }
        }}
      >
        <motion.section variants={variants}>
          <div className='sa-base-description'>
            Modular digital representative that is designed as a CRM on top of our network of clinics & hospitals. Its
            powerful micro apps enable pharmaceutical companies to reach out to a wide network of doctors, spread
            awareness of their products & services, get market feedback and boost operating income with minimal
            operational hassle.
          </div>
        </motion.section>

        <div className='row mt-offering-section-container'>
          <motion.section className='col' variants={variants}>
            <SRPharmaceuticalsOffering />
          </motion.section>
        </div>


        {/*<div className='row'>*/}
        {/*  <div className='col-lg-4' />*/}
        {/*  <motion.section className='col-lg-4' variants={variants}>*/}
        {/*    <DetailedSpecs>*/}
        {/*      <ComingSoon autoHeight />*/}
        {/*    </DetailedSpecs>*/}
        {/*  </motion.section>*/}
        {/*</div>*/}

        <motion.section variants={variants}>
          <div className='empty-space' />
          <Feedback
            title='Unfiltered feedback from pharmaceutical companies'
            data={srPharmaFeedbackData}
          />
        </motion.section>

      </motion.div>
      <div className='mobile'>
        <SRBookDemoPage cType={CTypes.SR_PHARMA} location={location} navigate={navigate} />
      </div>
      {/*<div className='desktop'>*/}
      {/*  <BookDemoPage location={location} navigate={navigate} />*/}
      {/*</div>*/}
    </div>
  );
};

export default SRPharmaDetailsPage;
